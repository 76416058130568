export const FareCalculator = (distance,time,rateDetails,instructionData, decimal)=>{  
    let baseCalculated = parseFloat(rateDetails.base_hour_price);
    let base_minute = parseFloat(rateDetails.base_hour) * 3600;
    if(time > base_minute){
        let extraTime = Math.floor((parseFloat(time) / 3600).toFixed(2) - parseFloat(rateDetails.base_hour)) + 1;
        baseCalculated = baseCalculated + parseFloat(extraTime * rateDetails.extra_hour_rate);
    }

    if(rateDetails.base_hour>0){
        baseCalculated = baseCalculated;
    }
    if(instructionData && instructionData.parcelTypeSelected){
        baseCalculated = baseCalculated + instructionData.parcelTypeSelected.amount;
    }
    if(instructionData && instructionData.optionSelected){
        baseCalculated = baseCalculated + instructionData.optionSelected.amount;
    }

    let total = baseCalculated;
    let convenienceFee = 0;
    if(rateDetails.convenience_fee_type && rateDetails.convenience_fee_type == 'flat'){
        convenienceFee = rateDetails.convenience_fees;
    }else{
        convenienceFee = (total*parseFloat(rateDetails.convenience_fees)/100);
    }
    let grand = total + convenienceFee;

    return {
        totalCost:parseFloat(total.toFixed(decimal)),
        grandTotal:parseFloat(grand.toFixed(decimal)),
        convenience_fees:parseFloat(convenienceFee.toFixed(decimal))
    }
     
}
